<template>
  <div class="form-wrapper">
    <div class="heading-5 mb-2">
      {{ platform !== "e-cross" ? $t("loginPage.welcome") + "!" : "TMS" }}
    </div>
    <p class="text-16 mb-2-5">{{ $t("loginPage.pleaseYourAcc") }}.</p>
    <form novalidate>
      <Alert
        v-if="errors.length > 0"
        class="mb-2"
        type="error"
        icon="x-circle"
        :message="errors[0]"
      />

      <NbTextInput
        required
        id="email-login"
        name="E-mail"
        class="mb-1-25"
        :placeholder="$t('placeholderEmail')"
        v-model="user.email"
      />
      <NbLoginPassInput
        required
        id="password-login"
        :name="$t('password')"
        class="mb-3"
        :placeholder="$t('password')"
        v-model="user.password"
      />

      <div class="d-flex justify-content-between align-items-center">
        <div class="">
          <a
            class="link-2"
            data-toggle="modal"
            @click.prevent="openResetPasswordModal"
            data-target="#modalPassword"
          >
            {{ $t("loginPage.forgotPassword") }}?
          </a>
        </div>
        <button @click.prevent="authenticate" class="button button-primary">
          <span v-if="loading">
            <span class="spinner-border spinner-border-sm"></span>
            {{ $t("loading") }}
          </span>
          <span v-if="!loading">{{ $t("loginPage.login") }} </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbLoginPassInput from "@/components/input/text/NbLoginPassInput.vue";
import Alert from "@/components/alerts/Alert.vue";

export default {
  name: "login-form",
  components: {
    NbTextInput,
    NbLoginPassInput,
    Alert,
  },
  props: {
    errors: {
      type: [Array, Object],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    platform: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      user: {
        email: "",
        password: "",
        agreement: false,
      },
    };
  },
  methods: {
    openResetPasswordModal() {
      this.$emit("openResetPasswordModal");
    },
    authenticate() {
      this.$emit("authenticate", this.user);
    },
  },
};
</script>
