<template>
  <div>
    <div>
      <div class="heading-4">{{ $t("loginPage.createYourAccount") }}</div>
      <div class="body-4 mb-4">{{ $t("loginPage.fillInfoToCreateAcc") }}.</div>
      <form>
        <NbNumberedCardForm
          class="mb-2"
          :number="1"
          :title="$t('loginPage.userData')"
          :subTitle="$t('loginPage.pleaseYourData')"
          :errors="errors_user"
          :formInputs="userInputs"
          @formChange="updateUser($event)"
        />
        <NbNumberedCardForm
          class="mb-4"
          :number="2"
          :title="$t('loginPage.companyData')"
          :subTitle="$t('loginPage.pleaseCompanyData')"
          :errors="errors_seller"
          :formInputs="sellerInputs"
          @formChange="updateSeller($event)"
        />
      </form>
      <div
        class="d-flex justify-content-between"
        style="border-bottom: 2px solid var(--gray-10); padding-bottom: 5rem"
      >
        <div>
          <div class="heading-3 mb-1">
            {{ $t("loginPage.hasRegistration") }}?
          </div>
          <div class="link-2" @click="switchIsLogin">
            {{ $t("loginPage.accessMyAcc") }} >
          </div>
        </div>
        <div>
          <button class="button-primary" v-on:click.prevent="validateRecaptcha">
            {{ $t("userCreatePage.title") }}<span class="ml-1">></span>
          </button>
        </div>
      </div>
      <footer class="text-center py-3">
        <a
          class="support-button"
          href="https://nobordistinc.freshdesk.com/support/home"
        >
        </a>
        <div class="body-3">
          {{ $t("loginPage.needHelp") }}?
          <a
            class="link-2"
            href="https://nobordistinc.freshdesk.com/support/home"
            >{{ $t("loginPage.talkWithOur") }}</a
          >.
        </div>
        <div class="body-3">
          {{ $t("loginPage.ifCheckOut") }}
          <a
            data-toggle="modal"
            v-b-modal.modal-agreement
            ref="modal-agreement"
            class="link-2"
            >{{ $t("loginPage.generalConditionsService") }}</a
          >.
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import SellerService from "@/services/SellerService";
import NProgress from "nprogress";
import NbNumberedCardForm from "@/components/forms/NbNumberedCardForm.vue";

const sellerService = new SellerService();

export default {
  name: "SellerCreate",
  components: {
    NbNumberedCardForm,
  },
  props: {
    classes: {
      type: String,
      required: false,
    },
    platform: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      account: {
        seller: {
          name: "",
          address: "",
          address_number: "",
          address_complement: "",
          zip_code: "",
          city: "",
          state: "",
          country: "",
          email: "",
          phone: "",
          site: "",
          phone_country_code: "+55",
        },
        user: {
          name: "",
          email: "",
          phone: "",
          recaptcha_token: null,
          phone_country_code: "+55",
        },
        platform: "",
      },
      user: {},
      seller: {},
      errors_seller: {},
      errors_user: {},
      countries: [
        { value: "BR", text: "Brazil" },
        { value: "US", text: "United States" },
        { value: "CN", text: "China" },
        { value: "FR", text: "France" },
        { value: "GB", text: "United Kingdom" },
      ],
    };
  },
  methods: {
    updateSeller(form) {
      this.seller = form;
    },
    updateUser(form) {
      this.user = form;
    },
    setRecaptchaToken() {
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha("login").then((token) => {
          this.account.seller = this.seller;
          this.account.user = this.user;
          this.account.user.recaptcha_token = token;
          this.createSeller();
        });
      });
    },
    validateRecaptcha() {
      this.setRecaptchaToken();
    },
    createSeller() {
      let dataToSend = JSON.parse(JSON.stringify(this.account));
      dataToSend.platform = this.platform;
      sellerService
        .setSeller(dataToSend)
        .then((response) => {
          if (response.status === 401) {
            this.showError(response);
            return;
          }
          this.switchIsLogin();
          this.$emit("sellerCreated");
          NProgress.done();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$refs.closeModalSellerCreate.click();
            this.showError(error.response);
            return;
          }
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.errors_seller = error.response.data.messages[0];
            this.errors_user = error.response.data.messages[1];
          }
          NProgress.done();
        });
    },
    updateCountryCode({ code }) {
      this.account.seller.phone_country_code = code;
    },
    updateCountryCodeUser({ code }) {
      this.account.user.phone_country_code = code;
    },
    showRecaptchaBadge() {
      this.$recaptchaInstance.showBadge();
    },
    showError(response = "Permission has not been granted") {
      this.$helpers.toast(response.data.messages[0], "danger");
    },
    switchIsLogin() {
      this.$emit("switchIsLogin");
    },
  },
  computed: {
    userInputs() {
      return [
        {
          required: true,
          id: "newUser_name",
          name: this.$t("fullName"),
          classes: "mb-3 w-48-5",
          placeholder: this.$t("fullName"),
          key: "name",
          error: this.errors_user.name,
        },
        {
          required: true,
          id: "newUser_phone",
          name: this.$t("phone"),
          classes: "mb-3 w-48-5",
          placeholder: "00000000000",
          key: "phone",
          error: this.errors_user.user_phone,
        },
        {
          required: true,
          id: "newUser_email",
          name: this.$t("email"),
          classes: "mb-3 w-100",
          placeholder: this.$t("placeholderEmail"),
          key: "email",
          error: this.errors_user.email,
        },
      ];
    },
    sellerInputs() {
      return [
        {
          required: true,
          id: "newSeller_name",
          name: this.$t("fullName"),
          classes: "mb-3 w-48-5",
          placeholder: this.$t("fullName"),
          key: "name",
        },
        {
          required: true,
          id: "newSeller_phone",
          name: this.$t("phone"),
          classes: "mb-3 w-48-5",
          placeholder: "00000000000",
          key: "phone",
        },
        {
          required: true,
          id: "newSeller_email",
          name: this.$t("email"),
          classes: "mb-3 w-48-5",
          placeholder: this.$t("placeholderEmail"),
          key: "email",
        },
        {
          required: true,
          id: "newSeller_site",
          name: this.$t("site"),
          classes: "mb-3 w-48-5",
          placeholder: "example.com",
          key: "site",
        },
        {
          type: "select",
          options: [
            { value: "BR", text: "Brazil" },
            { value: "US", text: "United States" },
            { value: "CN", text: "China" },
            { value: "FR", text: "France" },
            { value: "GB", text: "United Kingdom" },
          ],
          required: true,
          id: "newSeller_country",
          name: this.$t("country"),
          classes: "mb-3 w-48-5",
          placeholder: this.$t("country"),
          key: "country",
        },
        {
          required: true,
          id: "newSeller_city",
          name: this.$t("city"),
          classes: "mb-3 w-48-5",
          placeholder: this.$t("city"),
          key: "city",
        },
        {
          required: true,
          id: "newSeller_zip_code",
          name: this.$t("postalCode"),
          classes: "mb-3 w-100",
          placeholder: this.$t("postalCode"),
          key: "zip_code",
        },
        {
          required: true,
          id: "newSeller_address",
          name: this.$t("address"),
          classes: "mb-3 w-64-5",
          placeholder: this.$t("address"),
          key: "address",
        },
        {
          required: true,
          id: "newSeller_address_number",
          name: this.$t("number"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t("addressNumber"),
          key: "address_number",
        },
        {
          id: "newSeller_address_complement",
          name: this.$t("complement"),
          classes: "mb-3 w-100",
          placeholder: this.$t("addressNumber"),
          key: "address_complement",
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped></style>
