<template>
  <div class="nb-card-icon">
    <div class="mr-3 mb-2">
      <IconNumberBall width="29" :number="number" />
    </div>
    <div class="w-100">
      <div class="heading-3 mb-1">
        {{ title }}
        <span
          class="nb-badge"
          :class="isFormDone ? 'bg-success text-white' : 'bg-warning-10'"
          >{{ isFormDone ? `${$t("complete")}` : `${$t("incomplete")}` }}</span
        >
      </div>
      <div class="body-4 mb-3">
        {{ subTitle }}
      </div>
      <div class="d-flex justify-content-between flex-wrap">
        <div
          v-for="formInput in formInputs"
          :key="formInput.id"
          :class="formInput.classes"
        >
          <NbSelectInput
            v-if="formInput.type === 'select'"
            :required="formInput.required"
            :id="formInput.id"
            :name="formInput.name"
            :error="errors[formInput.key]"
            :options="formInput.options"
            v-model="formToEmit[formInput.key]"
          />

          <NbTextInput
            v-else
            :required="formInput.required"
            :id="formInput.id"
            :name="formInput.name"
            :placeholder="formInput.placeholder"
            :error="errors[formInput.key]"
            v-model="formToEmit[formInput.key]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconNumberBall from "../icons/IconNumberBall.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";

export default {
  components: {
    NbSelectInput,
    IconNumberBall,
    NbTextInput,
  },
  props: {
    number: {
      type: Number,
      require: true,
    },
    formInputs: {
      type: Array,
      require: true,
    },
    title: {
      type: String,
      require: false,
    },
    subTitle: {
      type: String,
      require: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formToEmit: {},
    };
  },
  created() {
    this.loadFormToEmit();
  },
  methods: {
    //inicializa o form
    loadFormToEmit() {
      let formKeys = {};
      this.formInputs.forEach((formInput) => {
        formKeys[formInput.key] = "";
      });
      this.formToEmit = JSON.parse(JSON.stringify(formKeys));
    },
  },
  computed: {
    isFormDone() {
      let isDone = false;
      let count = 0;

      const filteredFormToEmit = this.formInputs.filter((formInput) => {
        if (formInput.required) {
          return formInput;
        }
      });
      filteredFormToEmit.forEach((element) => {
        if (this.formToEmit[element.key]) {
          count++;
          return;
        }
        isDone = false;
      });

      if (count >= filteredFormToEmit.length) {
        isDone = true;
      }

      /* codego chechelento do vini
      const allRequiredKeys = Object.entries(this.formToEmit)
      let count = 0;
      allRequiredKeys.forEach(entry => {
        if(entry[1]) {
          count ++
        }
      });
      if(count >= filteredFormToEmit.length) {
        isDone = true;
      } */
      return isDone;
    },
  },
  watch: {
    formToEmit: {
      //deep: true,
      handler() {
        this.$emit("formChange", this.formToEmit);
      },
    },
  },
};
</script>

<style scoped>
.nb-card-icon {
  background: var(--white);
  border-radius: 0.25rem;
  padding: 2rem 5.62rem 2rem 3.12rem;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
</style>
