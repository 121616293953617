import { render, staticRenderFns } from "./AsiaLogin.vue?vue&type=template&id=19c36442&scoped=true&"
import script from "./AsiaLogin.vue?vue&type=script&lang=js&"
export * from "./AsiaLogin.vue?vue&type=script&lang=js&"
import style0 from "./AsiaLogin.vue?vue&type=style&index=0&id=19c36442&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c36442",
  null
  
)

export default component.exports